import { roundDecimal, roundOffFilter } from '../../../util/format';

export function userFunnelChart(data) {
	const se = [];
	const userValue = Object.values(data.data);
	for(let i = 0; i < data.legend.length; i += 1){
		let obj = {};
		obj.value = roundDecimal((userValue[i] / userValue[0]) * 100);
		obj.name = data.legend[i];
		se.push(obj);
	}
	const option = {
		title: {
			text: data.title || '',
			subtext: data.subtext || '',
			textStyle: {
				color: '#FFF',
			},
			subtextStyle: {
				color: '#FFF',
			},
		},
		legend: {
			left: 'left',
			top: 'bottom',
			textStyle: { color: '#FFF' },
			selectedMode: false,
			data: data.legend || []
		},
		tooltip: {
			show: true,
			trigger: 'item',
			confine: true,
			borderWidth: 0,
			textStyle: {
				color: '#000',
			},
			formatter: params => {
				// default legend show
				if(data.subTitle) {
					return `${params.marker}${data.subTitle[params.dataIndex]}: ${roundOffFilter(userValue[params.dataIndex])} (${params.value}%) <br/>
					${params.name}: ${roundOffFilter(data.subData[params.dataIndex])}`;
				}
				return `${params.marker}${params.name}: ${roundOffFilter(userValue[params.dataIndex])} (${params.value}%)`;
			}
		},
		series: [{
			color: data.color || ['#32C5E9','#67E0E3','#9FE6B8','#FFDB5C', '#A569BD'],
			name: data.name || '',
			type: 'funnel',
			orient: 'vertical',
			min: 0,
			max: 100,
			minSize: '0%',
			maxSize: '100%',
			sort: 'none',
			label: {
				show: true,
				position: 'right',
				textBorderColor: 'none',
				formatter: params => {
					// default label show
					return `${params.name}\n${roundOffFilter(userValue[params.dataIndex])}\n${params.value}%`;
				}
			},
			// emphasis: {
			// 	label: {
			// 		formatter: '{b}\n{c}%'
			// 	}
			// },
			data: se
		}]
	};
	return option;
}
