import light from '@/theme/light';

export function barPercent(){
	const option = {
		color: light.color,
		title: {
			text: '',
			x: 'left',
			textStyle: {
				color: '#FFF',
			},
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'line',
			},
			backgroundColor: '#FFF',
			confine: true,
			textStyle: { color: 'black' },
			formatter: '',
		},
		toolbox: {
			show: true,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
						stack: 'Stack'
					},
					type: ['line', 'bar', 'stack']
				},
			},
		},
		xAxis: {
			type: 'category',
			data: [],
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				// interval: 0,
				color: '#FFF',
			},
		},
		yAxis:  {
			type: 'value',
			min: '0',
			max: '101',
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				formatter: '{value}%',
				color: '#FFF',
				fontSize: 11
			},
			splitLine: { lineStyle: { color: '#37474F' } },
		},
		series: []
	};
	return option;	
}