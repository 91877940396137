import light from '@/theme/light';
import { nFormatter} from '../../../util/format';

export function basicLineChart(){
	const option = {
		color: light.color,
		title: {
			text: '',
			x: 'left',
			textStyle: {
				color: '#FFF',
			},
		},
		toolbox: {
			show: false,
			itemSize: 30,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
						stack: 'Stack'
					},
					type: ['line', 'bar', 'stack']
				},
			},
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'line',
			},
			confine: true,
			textStyle: {
				color: 'black',
			},
			formatter: '',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8'
		},
		xAxis: {
			type: 'category',
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				color: '#FFF',
			},
			data: [],
		},
		yAxis: {
			type: 'value',
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					if (value < 0) {
						return `-${nFormatter(Math.abs(value), 1)}`;
					}
					return `${nFormatter(value, 1)}`;
				},
			},
			splitLine: { lineStyle: { color: '#37474F' } },
		},
		series: {
			name: '',
			type: 'line',
			label: {},
			data: [],
		},
	};
	return option;
}
