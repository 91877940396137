<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
			v-tab {{ $t("charts.everyMonth") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							MonthlyPicker(:tab='tab', @emitUpdateMonth='updateMonth')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			MainSelectItem(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
				v-card.pa-4.filter-wrap.div-w(v-if='timeSwitch === 1')
					v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
						v-row.py-1
							v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
								span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
							v-col.mr-2.py-1.px-3(cols='auto')
								v-img.researchImg(max-width='25px', :src='imgSrc')
					v-row.justify-center(v-if='basic.items.length !== 0')
						v-col.px-0(cols='12')
							v-simple-table.dataTableWrap
								template(v-slot:default)
									tbody
										tr(v-for='item in basic.items', :key='item.name')
											td {{ item.name }}
											td.red--text.text-right(v-if='item.negative') {{ item.value }}
											td.text-right(v-else) {{ item.value }}
					v-row.justify-center
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(turnOverBar).length !== 0')
							v-chart(:option='turnOverBar', ref='turnOverBar', autoresize)
						v-col.chartWrap.mixChart(cols='12', lg='6', v-if='Object.keys(netWinBar).length !== 0')
							v-col#netWinSelectBox.dataSelectBox
								netWinPicker(:netWinDataStatus='netWinData', @emitupdateNetWinData='updateNetWinData')
							v-chart.chartMoveUp_more(:option='netWinBar', ref='netWinBar', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(revenueBar).length !== 0')
							v-chart(:option='revenueBar', ref='revenueBar', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(acuLine).length !== 0')
							v-chart(:option='acuLine', ref='acuLine', autoresize)
						//- v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(avgRevenueLine).length !== 0')
						//- 	v-chart(:option='avgRevenueLine', ref='avgRevenueLine', autoresize)
					v-row.justify-center
						v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(regFunnel).length !== 0')
							v-chart(:option='regFunnel', ref='regFunnel', @legendselectchanged='legendselectchanged', autoresize)
						ChartsSwitch(:firstOption='regLine' :secondOption='regPercent')
					v-row.justify-center
						v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(devicePie).length !== 0')
							v-chart(:option='devicePie', ref='devicePie', autoresize)
						ChartsSwitch(:firstOption='countLine' :secondOption='countBar')
					v-row.justify-center
						v-col.chartWrap.dataSelectChart(cols='12', lg='4', v-if='Object.keys(domainBar).length !== 0')
							v-col.dataSelectBox
								DataPicker(:dataStatus='data', @emitupdateData='updateData')
							v-chart.chartMoveUp_more(:option='domainBar', ref='domainBar', autoresize)
						v-col.chartWrap.dataSelectChart(cols='12', lg='4', v-if='Object.keys(brandBar).length !== 0')
							v-col.dataSelectBox
								DataPicker(:dataStatus='data', @emitupdateData='updateData')
							v-chart.chartMoveUp_more(:option='brandBar', ref='brandBar', autoresize)
						v-col.chartWrap.dataSelectChart(cols='12', lg='4', v-if='Object.keys(gameTypeBar).length !== 0')
							v-col.dataSelectBox
								DataPicker(:dataStatus='data', @emitupdateData='updateData')
							v-chart.chartMoveUp_more(:option='gameTypeBar', ref='gameTypeBar', autoresize)
						v-col.px-1(cols='12', lg='6', v-if='Object.keys(netWinByDomain).length !== 0')
							span.title {{ $t("charts.netWinPerDomain") }}
							v-data-table.elevation-1.dataTableWrap(
								:items-per-page='itemPerPage',
								:headers='domainHeaders',
								:items='netWinByDomain',
								hide-default-footer,
								style='width: 100%',
								:expanded.sync='domainExpanded',
								item-key='dim_site_id',
								show-expand,
								:mobile-breakpoint='0'
							)
								template(v-slot:item.house_net_win='{ item }')
									span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
									span(v-else) {{ item.house_net_win | commaFormatter }}
								template(v-slot:expanded-item='{ item }')
									td.pa-2.expandedTd(:colspan='3')
										v-data-table.pa-2.expandedTable(
											:items-per-page='itemPerPage',
											:headers='domainSubHeaders',
											:items='item.items',
											hide-default-footer,
											style='width: 100%',
											:mobile-breakpoint='0',
											dense
										)
											template(v-slot:item.house_net_win='{ item }')
												span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
												span(v-else) {{ item.house_net_win | commaFormatter }}
						v-col.px-1(cols='12', lg='6', v-if='Object.keys(netWinByBrand).length !== 0')
							span.title {{ $t("charts.netWinPerBrand") }}
							v-data-table.elevation-1.dataTableWrap(
								:items-per-page='itemPerPage',
								:headers='brandHeaders',
								:items='netWinByBrand',
								hide-default-footer,
								style='width: 100%',
								:expanded.sync='brandExpanded',
								item-key='dim_brand_code',
								show-expand,
								:mobile-breakpoint='0',
								:class='netWinByBrand.negative ? "b" : "a"'
							)
								template(v-slot:item.house_net_win='{ item }')
									span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
									span(v-else) {{ item.house_net_win | commaFormatter }}
								template(v-slot:expanded-item='{ item }')
									td.pa-2.expandedTd(:colspan='3')
										v-data-table.pa-2.expandedTable(
											:items-per-page='itemPerPage',
											:headers='brandSubHeaders',
											:items='item.items',
											hide-default-footer,
											style='width: 100%',
											:mobile-breakpoint='0',
											dense
										)
											template(v-slot:item.house_net_win='{ item }')
												span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
												span(v-else) {{ item.house_net_win | commaFormatter }}
				v-card.pa-4.filter-wrap.div-w(v-if='timeSwitch === 2')
					v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
						v-row.py-1
							v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
								span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
							v-col.mr-2.py-1.px-3(cols='auto')
								v-img.researchImg(max-width='25px', :src='imgSrc')
					v-row.justify-center.mb-5(v-if='basic.items.length !== 0')
						v-col.pa-0(cols='12')
							v-simple-table.dataTableWrap
								template(v-slot:default)
									tbody
										tr(v-for='item in basic.items', :key='item.name')
											td {{ item.name }}
											td.red--text.text-right(v-if='item.negative') {{ item.value }}
											td.text-right(v-else) {{ item.value }}
					v-row.justify-center
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(turnOverBar).length !== 0')
							v-chart(:option='turnOverBar', ref='turnOverBar', autoresize)
						v-col.chartWrap.mixChart(cols='12', lg='6', v-if='Object.keys(netWinBar).length !== 0')
							v-col#netWinSelectBox.dataSelectBox
								netWinPicker(:netWinDataStatus='netWinData', @emitupdateNetWinData='updateNetWinData')
							v-chart.chartMoveUp_more(:option='netWinBar', ref='netWinBar', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(revenueBar).length !== 0')
							v-chart(:option='revenueBar', ref='revenueBar', autoresize)
						v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(acuLine).length !== 0')
							v-chart(:option='acuLine', ref='acuLine', autoresize)
						//- v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(avgRevenueLine).length !== 0')
						//- 	v-chart(:option='avgRevenueLine', ref='avgRevenueLine', autoresize)
					v-row.justify-center
						v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(regFunnel).length !== 0')
							v-chart(:option='regFunnel', ref='regFunnel', autoresize)
						ChartsSwitch(:firstOption='regLine' :secondOption='regPercent')
					v-row.justify-center
						v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(devicePie).length !== 0')
							v-chart(:option='devicePie', ref='devicePie', autoresize)
						ChartsSwitch(:firstOption='countLine' :secondOption='countBar')
					v-row.justify-center
						v-col.chartWrap.dataSelectChart(cols='12', lg='4', v-if='Object.keys(domainBar).length !== 0')
							v-col.dataSelectBox
								DataPicker(:dataStatus='data', @emitupdateData='updateData')
							v-chart.chartMoveUp_more(:option='domainBar', ref='domainBar', autoresize)
						v-col.chartWrap.dataSelectChart(cols='12', lg='4', v-if='Object.keys(brandBar).length !== 0')
							v-col.dataSelectBox
								DataPicker(:dataStatus='data', @emitupdateData='updateData')
							v-chart.chartMoveUp_more(:option='brandBar', ref='brandBar', autoresize)
						v-col.chartWrap.dataSelectChart(cols='12', lg='4', v-if='Object.keys(gameTypeBar).length !== 0')
							v-col.dataSelectBox
								DataPicker(:dataStatus='data', @emitupdateData='updateData')
							v-chart.chartMoveUp_more(:option='gameTypeBar', ref='gameTypeBar', autoresize)
						v-col.px-1(cols='12', lg='6', v-if='Object.keys(netWinByDomain).length !== 0')
							span.title {{ $t("charts.netWinPerDomain") }}
							v-data-table.elevation-1.dataTableWrap(
								:items-per-page='itemPerPage',
								:headers='domainHeaders',
								:items='netWinByDomain',
								hide-default-footer,
								style='width: 100%',
								:expanded.sync='domainExpanded',
								item-key='dim_site_id',
								show-expand,
								:mobile-breakpoint='0'
							)
								template(v-slot:item.house_net_win='{ item }')
									span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
									span(v-else) {{ item.house_net_win | commaFormatter }}
								template(v-slot:expanded-item='{ item }')
									td.pa-2.expandedTd(:colspan='3')
										v-data-table.pa-2.expandedTable(
											:items-per-page='itemPerPage',
											:headers='domainSubHeaders',
											:items='item.items',
											hide-default-footer,
											style='width: 100%',
											:mobile-breakpoint='0',
											dense
										)
											template(v-slot:item.house_net_win='{ item }')
												span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
												span(v-else) {{ item.house_net_win | commaFormatter }}
						v-col.px-1(cols='12', lg='6', v-if='Object.keys(netWinByBrand).length !== 0')
							span.title {{ $t("charts.netWinPerBrand") }}
							v-data-table.elevation-1.dataTableWrap(
								:items-per-page='itemPerPage',
								:headers='brandHeaders',
								:items='netWinByBrand',
								hide-default-footer,
								style='width: 100%',
								:expanded.sync='brandExpanded',
								item-key='dim_brand_code',
								show-expand,
								:mobile-breakpoint='0',
								:class='netWinByBrand.negative ? "b" : "a"'
							)
								template(v-slot:item.house_net_win='{ item }')
									span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
									span(v-else) {{ item.house_net_win | commaFormatter }}
								template(v-slot:expanded-item='{ item }')
									td.pa-2.expandedTd(:colspan='3')
										v-data-table.pa-2.expandedTable(
											:items-per-page='itemPerPage',
											:headers='brandSubHeaders',
											:items='item.items',
											hide-default-footer,
											style='width: 100%',
											:mobile-breakpoint='0',
											dense
										)
											template(v-slot:item.house_net_win='{ item }')
												span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
												span(v-else) {{ item.house_net_win | commaFormatter }}
</template>

<script>
import _ from 'lodash';
import ChartsSwitch from '@/components/Charts/ChartsSwitch';
import ECharts from 'vue-echarts';
import statusCode from '@/assets/constant/statusCode';
import deviceType from '@/assets/constant/deviceType';
import { bar, pie, line } from '@/assets/echarts/basicCommon/dataMointor';
import { pie as norPie } from '@/assets/echarts/ocmsCommon/customer/single';
import { barPercent as norBar, line as norLine } from '@/assets/echarts/ocmsCommon/house/reg';
import { mixBar_Line } from '@/assets/echarts/ocmsCommon/mixBar_Line';
import { basicLineChart } from '@/assets/echarts/ocmsCommon/basicLineChart';
import { barPercent } from '@/assets/echarts/ocmsCommon/barPercent';
import { userFunnelChart } from '@/assets/echarts/ocmsCommon/funnel';
import { getDaily, getMonthly } from '@/api/DataMonitor';
import { mapActions, mapGetters } from 'vuex';
import { revenueChart } from '@/assets/echarts/ocmsCommon/revenue';
import {
	commaFormatter,
	nFormatter,
	trans,
	transData,
	toThousandslsFilter,
	rateOfExchange,
	roundOffFilter,
	rdPercent,
	roundDecimal,
	getNaN,
} from '@/util/format';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import MonthlyPicker from '@/components/DatePicker/MonthPicker_ocms';
import DataPicker from '@/components/DataPicker/DataPicker';
import NetWinPicker from '@/components/DataPicker/NetWinPicker';
import ExportBtn from '@/components/ExportBtn';
import MainSelectItem from '@/components/MainSelectItemOCMS';
import { exportXlsx } from '@/util/xlsx';
import COLORS from '@/theme/color';

export default {
	components: {
		'v-chart': ECharts,
		ChartsSwitch,
		ValidationObserver,
		ValidationProvider,
		DaliyPicker,
		MonthlyPicker,
		DataPicker,
		NetWinPicker,
		ExportBtn,
		MainSelectItem,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			getDailyResult: {},
			getMonthlyResult: {},
			getExportResult: {},
			getNetWinDailyResult: {},
			getNetWinMonthlyResult: {},
			currencyStatus: true,
			show: false,
			helperShow: false,
			percentSwitchShow: false,
			percentSwitch: false,
			percentSwitchTitle: this.$t('charts.showPercent'),
			loading: false,
			timeSwitch: 1,
			basic: {
				headers: [
					{ text: '', align: 'left', sortable: false, value: 'name' },
					{ text: '', sortable: false, value: 'value' },
				],
				items: [],
			},
			regUsersData: [],
			dau: 0,
			deopsit: 0,
			withdraw: 0,
			dates: [],
			data: this.$t('charts.turnover'),
			netWinData: this.$t('charts.promoCredit'),
			searchDates: [],
			dateType: 'MM/DD',
			value: [],
			domainMap: {},
			currency: '',
			allLoginCount: 0,
			monthlyROE: {},
			domainROE: {},
			brandROE: {},
			gameKindROE: {},
			domainBar: {},
			brandBar: {},
			gameTypeBar: {},
			devicePie: {},
			turnOverBar: {},
			netWinBar: {},
			acuLine: {},
			avgRevenueLine: {},
			revenueBar: {},
			regLine: {},
			regPercent: {},
			regFunnel: {},
			countLine: {},
			countBar: {},
			funnelTmpData: {},
			countColor: [COLORS.amethyst[5], COLORS.orange[5], COLORS.hotpink, COLORS.nephritis[5], COLORS.peter_river[5], COLORS.dark_gray_indigo],
			netWinByDomain: [],
			netWinByBrand: [],
			itemPerPage: 100,
			domainExpanded: [],
			brandExpanded: [],
			domainHeaders: [
				{
					text: this.$t('charts.domain'),
					align: 'left',
					value: 'dim_site_id',
				},
				{ text: this.$t('common.sort'), align: 'right', value: 'house_net_win' },
				{ text: '', value: 'data-table-expand' },
			],
			domainSubHeaders: [
				{ text: this.$t('charts.brand'), value: 'dim_brand_code' },
				{ text: this.$t('common.sort'), align: 'right', value: 'house_net_win' },
			],
			brandHeaders: [
				{
					text: this.$t('charts.brand'),
					align: 'left',
					value: 'dim_brand_code',
				},
				{ text: this.$t('common.sort'), align: 'right', value: 'house_net_win' },
				{ text: '', value: 'data-table-expand' },
			],
			brandSubHeaders: [
				{ text: this.$t('charts.gameKind'), value: 'game_kind_name' },
				{ text: this.$t('common.sort'), align: 'right', value: 'house_net_win' },
			],
			tab: null,
			imgSrc: require('@/assets/image/research.png'),
		};
	},
	mounted() {
		this.initData();
		this.matchedIndexPin();
		this.onResize();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		smoothDown() {
			$('html, body').animate(
				{
					scrollTop: $('.searchBtn').offset().top,
				},
				500
			);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] == 'daily') {
					this.tab = 0;
				} else if (operatorDefaultRouter[1] == 'monthly') {
					this.tab = 1;
				}
			}
		},
		async send() {
			this.allClean();
			this.show = !this.show;
			this.percentSwitchShow = true;
			this.percentSwitch = false;
			this.loading = !this.loading;

			try {
				let domain = [];
				for (let i of this.value) {
					if (this.domainMap[i].indexOf('-')) {
						let tmpJ = this.domainMap[i].split('-');
						domain.push(tmpJ[0]);
					} else {
						domain.push(this.domainMap[i]);
					}
				}
				if (this.timeSwitch === 1) {
					const reqData = {
						startDate: this.$moment(this.dates[0]).format('YYYY-MM-DD'),
						endDate: this.$moment(this.dates[1]).format('YYYY-MM-DD'),
						domain,
						currency: this.currency
					};
					this.searchDates = [reqData.startDate, reqData.endDate];
					const res = await getDaily(reqData);
					if (res.status === statusCode.STATUS_OK) {
						localStorage.setItem('currency', this.currency);
						this.dateType = 'MM/DD';
						const result = res.result.res;
						this.getDailyResult = JSON.parse(JSON.stringify(result));
						this.getNetWinDailyResult = JSON.parse(JSON.stringify(result));
						this.getExportResult = JSON.parse(JSON.stringify(result));
						this.cvtAll = JSON.parse(JSON.stringify(result.ctx_cvt_all));
						this.cvtOrg = JSON.parse(JSON.stringify(result.ctx_cvt_org));
						this.loginSum = result.ctx_login_recap;
						this.loginOrg = result.ctx_login_termly;
						this.termly = JSON.parse(JSON.stringify(result.ctx_basic_termly));
						this.gaming = JSON.parse(JSON.stringify(result.ctx_gm));
						this.recap = JSON.parse(JSON.stringify(result.ctx_basic_recap[0]));

						this.termly.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						this.loginOrg.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						this.dailyCount = trans(this.loginOrg, 'dim_ptt', 'login_count');

						this.turnOverChart(this.termly);
						this.netWinChart(this.termly);
						this.acuChart(this.termly);
						this.revenueChart(this.termly);
						// this.avgRevenueChart(this.termly);
						this.regChart(this.cvtOrg);
						this.regPercentChart(this.cvtOrg);
						this.gaming.sort((a, b) => (a.dim_site_id > b.dim_site_id ? 1 : -1));
						this.domainChart(await trans(this.gaming, 'dim_site_id', 'apv_bet'), await trans(this.gaming, 'dim_site_id', 'house_net_win'));
						this.gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
						this.brandChart(await trans(this.gaming, 'dim_brand_code', 'apv_bet'), await trans(this.gaming, 'dim_brand_code', 'house_net_win'));
						this.gaming.sort((a, b) => (a.game_kind_name > b.game_kind_name ? 1 : -1));
						this.gameTypeChart(
							await trans(this.gaming, 'game_kind_name', 'apv_bet'),
							await trans(this.gaming, 'game_kind_name', 'house_net_win')
						);
						this.deviceChart(this.loginSum);
						await this.deviceLineChart(this.loginOrg);
						this.deviceBarChart(this.loginOrg);
						this.dataTable(this.recap);
						this.gaming.sort((a, b) => (a.dim_site_id > b.dim_site_id ? 1 : -1));
						this.netWinByDomain = await transData(this.gaming, 'dim_site_id', 'dim_brand_code', 'house_net_win');
						this.gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
						this.netWinByBrand = await transData(this.gaming, 'dim_brand_code', 'game_kind_name', 'house_net_win');
					} else {
						this.allClean();
						let noMatchData = this.$t('error.noMatchData');
						this.errorDialogStatus(noMatchData);
					}
					this.smoothDown();
					this.tab = 0;
				}
				if (this.timeSwitch === 2) {
					const reqData = {
						startDate: this.$moment(this.dates[0]).startOf('month').format('YYYY-MM-DD'),
						endDate: this.$moment(this.dates[1]).startOf('month').add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD'),
						domain,
						currency: this.currency
					};
					this.searchDates = [reqData.startDate, reqData.endDate];
					const res = await getMonthly(reqData);
					if (res.status === statusCode.STATUS_OK) {
						localStorage.setItem('currency', this.currency);
						this.dateType = 'YYYY/MM';
						const result = res.result.res;
						this.getMonthlyResult = JSON.parse(JSON.stringify(result));
						this.getNetWinMonthlyResult = JSON.parse(JSON.stringify(result));
						this.getExportResult = JSON.parse(JSON.stringify(result));
						this.cvtAll = JSON.parse(JSON.stringify(result.ctx_cvt_all));
						this.cvtOrg = JSON.parse(JSON.stringify(result.ctx_cvt_org));
						this.loginSum = result.ctx_login_recap;
						this.loginOrg = result.ctx_login_termly;
						this.termly = JSON.parse(JSON.stringify(result.ctx_basic_termly));
						this.gaming = JSON.parse(JSON.stringify(result.ctx_gm));
						this.recap = JSON.parse(JSON.stringify(result.ctx_basic_recap[0]));

						this.termly.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						this.loginOrg.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						this.dailyCount = trans(this.loginOrg, 'dim_ptt', 'login_count');

						this.turnOverChart(this.termly);
						this.netWinChart(this.termly);
						this.acuChart(this.termly);
						this.revenueChart(this.termly);
						// this.avgRevenueChart(this.termly);
						this.regChart(this.cvtOrg);
						this.regPercentChart(this.cvtOrg);
						this.gaming.sort((a, b) => (a.dim_site_id > b.dim_site_id ? 1 : -1));
						this.domainChart(await trans(this.gaming, 'dim_site_id', 'apv_bet'), await trans(this.gaming, 'dim_site_id', 'house_net_win'));
						this.gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
						this.brandChart(await trans(this.gaming, 'dim_brand_code', 'apv_bet'), await trans(this.gaming, 'dim_brand_code', 'house_net_win'));
						this.gaming.sort((a, b) => (a.game_kind_name > b.game_kind_name ? 1 : -1));
						this.gameTypeChart(
							await trans(this.gaming, 'game_kind_name', 'apv_bet'),
							await trans(this.gaming, 'game_kind_name', 'house_net_win')
						);
						this.deviceChart(this.loginSum);
						await this.deviceLineChart(this.loginOrg);
						this.deviceBarChart(this.loginOrg);
						this.dataTable(this.recap);
						this.gaming.sort((a, b) => (a.dim_site_id > b.dim_site_id ? 1 : -1));
						this.netWinByDomain = await transData(this.gaming, 'dim_site_id', 'dim_brand_code', 'house_net_win');
						this.gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
						this.netWinByBrand = await transData(this.gaming, 'dim_brand_code', 'game_kind_name', 'house_net_win');
					}
					this.smoothDown();
					this.tab = 1;
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				this.allClean();
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async dataTable(res) {
			this.basic.items = [
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.apv_bet'), value: roundOffFilter(res.ttl_apv_bet)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.house_net_win'), value: roundOffFilter(res.ttl_house_net_win)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.dpst_amt'), value: roundOffFilter(res.ttl_dpst_amt)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.wdrl_amt'), value: roundOffFilter(res.ttl_wdrl_amt)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.revenue_amt'), value: roundOffFilter(res.ttl_revenue_amt)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.bonus_amt'), value: roundOffFilter(res.ttl_bonus_amt)},
				// {name: this.$t('modifier.prefix_ttl') + this.$t('metric.bonus_20_amt'), value: roundOffFilter(res.ttl_bonus_20_amt)},
				// {name: this.$t('modifier.prefix_ttl') + this.$t('metric.profit_amt'), value: roundOffFilter(res.ttl_profit_amt)},

				{name: this.$t('metric.house_edge'), value: rdPercent(res.house_edge)},
				{name: this.$t('metric.bonus_rate'), value: rdPercent(res.bonus_rate)},

				{name: this.$t('modifier.prefix_avg') + this.$t('metric.gm_users'), value: roundOffFilter(res.avg_gm_users)},
				{name: this.$t('modifier.prefix_avg') + this.$t('metric.apv_bet'), value: roundOffFilter(res.avg_apv_bet)},
				{name: this.$t('modifier.prefix_avg') + this.$t('metric.house_net_win'), value: roundOffFilter(res.avg_house_net_win)},
				{name: this.$t('modifier.prefix_avg') + this.$t('metric.dpst_amt'), value: roundOffFilter(res.avg_dpst_amt)},
				{name: this.$t('modifier.prefix_avg') + this.$t('metric.wdrl_amt'), value: roundOffFilter(res.avg_wdrl_amt)},
				{name: this.$t('modifier.prefix_avg') + this.$t('metric.revenue_amt'), value: roundOffFilter(res.avg_revenue_amt)},
				{name: this.$t('modifier.prefix_avg') + this.$t('metric.bonus_amt'), value: roundOffFilter(res.avg_bonus_amt)},
				// {name: this.$t('modifier.prefix_avg') + this.$t('metric.profit_amt'), value: roundOffFilter(res.avg_profit_amt)},
			];
			this.basic.items.forEach(e_1 => {
				if(parseFloat(e_1.value) < 0)
					e_1.negative = true;
			});
		},
		// Daily(Monthly) Turnover Chart
		async turnOverChart(res) {
			const data = [];
			const xAxisData = [];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					data.push(res[i].apv_bet);
				}
			}
			this.turnOverBar = await bar();
			this.turnOverBar.color = COLORS.blue;
			if (this.timeSwitch == 1) {
				this.turnOverBar.title.text = this.$t('charts.overViewTurnOverD');
				this.turnOverBar.tooltip.formatter = (params) => {
					return `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>
					${params[0].marker}${params[0].seriesName}: ${roundOffFilter(params[0].value)}`;
				};
			} else if (this.timeSwitch == 2) {
				this.turnOverBar.title.text = this.$t('charts.overViewTurnOverM');
				this.turnOverBar.tooltip.formatter = (params) => {
					return `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>
					${params[0].marker}${params[0].seriesName}: ${roundOffFilter(params[0].value)}`;
				};
			}
			this.turnOverBar.xAxis.data = xAxisData;
			this.turnOverBar.xAxis.axisLabel.formatter = (value) => {
				return this.$moment(value).format(this.dateType);
			};
			this.turnOverBar.yAxis[0].axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.turnOverBar.series.name = this.$t('charts.turnover');
			this.turnOverBar.series.markPoint = {
				label: {
					formatter: (parameter) => nFormatter(parameter.value, 1),
				},
				symbolSize: [1, 1],
				data: [
					{ type: 'max', name: '最大值', label: { position: 'top', color: '#FFF' } },
					{ type: 'min', name: '最小值', label: { position: 'top', color: '#FFF' } },
				],
			};
			this.turnOverBar.series.data = data;
		},
		// Daily(Monthly) NetWin Chart
		async netWinChart(res) {
			const xAxisData = [];
			const netWinData = [];
			const promoCreditData = [];
			const profitData = [], heData = [];
			const HEPercentData = {};
			const profitPercentData = {};
			let netWinLineName = this.netWinData;
			let netWinLineData = [];
			let netWinLineColor = '';
			let netWinLineYAxis = 0;

			const legendData = [this.$t('charts.netWin'), netWinLineName];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					netWinData.push(roundDecimal(res[i].house_net_win));
					promoCreditData.push(_.toInteger(res[i].bonus_amt));
					profitData.push(res[i].profit_amt);
					heData.push(roundDecimal((res[i].house_edge) * 100));
					// HEPercentData[res[i].dim_ptt] = (res[i].house_net_win / res[i].apv_bet) * 100;
					profitPercentData[res[i].dim_ptt] = roundDecimal(res[i].profit_rate * 100);
				}
			}

			this.netWinBar = await mixBar_Line();
			if (this.timeSwitch == 1) {
				this.netWinBar.title.text = this.$t('charts.overViewNetWinD');
				this.netWinBar.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for (let i of params) {
						if(i.seriesName === this.$t('charts.HouseEdge')) {
							str += `${i.marker}${i.seriesName}: ${i.value}%<br/>`;
						} else {
							str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
						}
					}
					str += `
						<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#ce93d8"></span>${this.$t(
							'charts.profitRate'
						)}: ${profitPercentData[params[0].name]}%`;
					return str;
				};
				this.netWinBar.xAxis.axisLabel.formatter = (value) => {
					return this.$moment(value).format(this.dateType);
				};
			} else if (this.timeSwitch == 2) {
				this.netWinBar.title.text = this.$t('charts.overViewNetWinM');
				this.netWinBar.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for (let i of params) {
						if(i.seriesName === this.$t('charts.HouseEdge')) {
							str += `${i.marker}${i.seriesName}: ${i.value}%<br/>`;
						} else {
							str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
						}
					}
					str += `
						<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#ce93d8"></span>${this.$t(
							'charts.profitRate'
						)}: ${profitPercentData[params[0].name]}%`;
					return str;
				};
				this.netWinBar.xAxis.axisLabel.formatter = (value) => {
					return this.$moment(value).format(this.dateType);
				};
			}
			this.netWinBar.grid = { top: '23%', left: '16%' };
			this.netWinBar.tooltip.axisPointer.type = 'shadow';
			this.netWinBar.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				data: [{ name: legendData[0] }, { name: legendData[1], icon: 'circle' }],
			};
			this.netWinBar.xAxis.data = xAxisData;
			this.netWinBar.yAxis[0].axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.netWinBar.series.markPoint = {
				label: {
					formatter: (parameter) => {
						let value;
						if (parameter.value >= 0) {
							value = nFormatter(parameter.value, 1);
						} else {
							value = `-${nFormatter(Math.abs(parameter.value), 1)}`;
						}
						return value;
					},
				},
				symbolSize: [1, 1],
				data: [
					{ type: 'max', name: '最大值', label: { position: 'top', color: 'white' } },
					{ type: 'min', name: '最小值', label: { position: 'top', color: 'white' } },
				],
			};

			if (netWinLineName == this.$t('charts.promoCredit')) {
				netWinLineData = promoCreditData;
				netWinLineColor = '#ffcdd2';
				netWinLineYAxis = 0;
			}
			if (netWinLineName === this.$t('charts.profit')) {
				netWinLineData = profitData;
				netWinLineColor = '#E1E100';
				netWinLineYAxis = 0;
			}
			if (netWinLineName === this.$t('charts.HouseEdge')) {
				netWinLineData = heData;
				netWinLineColor = '#42a5f5';
				netWinLineYAxis = 1;
			}
			this.netWinBar.series = [
				{
					name: this.$t('charts.netWin'),
					type: 'bar',
					data: netWinData,
					color: COLORS.dark_green,
				},
				{
					name: netWinLineName,
					type: 'line',
					data: netWinLineData,
					color: netWinLineColor,
					yAxisIndex: netWinLineYAxis,
				},
			];
		},
		// Daily(Monthly) Active Users Chart
		async acuChart(res) {
			const gmUsersData = [],
				dpstData = [],
				promoData = [],
				loginData = [],
				regData = [],
				xAxisData = [];
			const legendData = [
				this.$t('charts.regUsersPerDay'),
				this.$t('charts.loginUsersPerDay'),
				this.$t('charts.gamingUsersPerDay'),
				this.$t('charts.dpstUsersPerDay'),
				this.$t('charts.promoUsersPerDay'),
			];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					gmUsersData.push(res[i].gm_users);
					dpstData.push(res[i].dpst_users);
					promoData.push(res[i].promo_join_users);
					loginData.push(res[i].login_users);
					regData.push(res[i].reg_users);
				}
			}
			this.acuLine = await basicLineChart();
			if (this.timeSwitch == 1) {
				this.acuLine.title.text = this.$t('charts.overViewDAU');
			} else if (this.timeSwitch == 2) {
				this.acuLine.title.text = this.$t('charts.overViewMAU');
			}
			this.acuLine.xAxis.axisLabel.formatter = (value) => {
				return this.$moment(value).format(this.dateType);
			};
			this.acuLine.xAxis.data = xAxisData;
			this.acuLine.grid = { bottom: '24%', left: '13%' };
			this.acuLine.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				icon: 'circle',
				data: [
					{ name: legendData[0] },
					{ name: legendData[1] },
					{ name: legendData[2] },
					{ name: legendData[3] },
					{ name: legendData[4] },
				],
			};
			// this.acuLine.tooltip.backgroundColor = 'rgba(50,50,50,0.7)';
			// this.acuLine.tooltip.textStyle.color = 'white';
			this.acuLine.tooltip.formatter = (params) => {
				let dateName;
				if (this.timeSwitch == 1) {
					dateName = this.$t('charts.date');
				} else if (this.timeSwitch == 2) {
					dateName = this.$t('charts.month');
				}
				let str = `${dateName}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
				for (let i of params) {
					str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
				}
				return str;
			};
			this.acuLine.series = [
				{
					type: 'line',
					name: this.$t('charts.regUsersPerDay'),
					data: regData,
					color: COLORS.light_purple,
				},
				{
					type: 'line',
					name: this.$t('charts.loginUsersPerDay'),
					data: loginData,
					color: COLORS.water_blue_green,
				},
				{
					type: 'line',
					name: this.$t('charts.gamingUsersPerDay'),
					data: gmUsersData,
					color: COLORS.light_green,
				},
				{
					type: 'line',
					name: this.$t('charts.dpstUsersPerDay'),
					data: dpstData,
					color: COLORS.mustard,
				},
				{
					type: 'line',
					name: this.$t('charts.promoUsersPerDay'),
					data: promoData,
					color: COLORS.light_pink,
				},
			];
		},
		// Daily(Monthly) Deposit
		async revenueChart(res) {
			const barData = [];
			const lineData = [];
			const xAxisData = [];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					barData.push(res[i].dpst_amt);
					lineData.push(res[i].revenue_amt);
				}
			}
			this.revenueBar = await revenueChart({
				date: {
					name: this.$t('charts.date'),
					format: this.dateType,
				},
				xAxis: {
					data: xAxisData,
				},
				bar: {
					name: this.$t('charts.dpst'),
					data: barData,
				},
				line: {
					name: this.$t('charts.revenue'),
					data: lineData,
				},
			});
			this.revenueBar.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				data: [{ name: this.$t('charts.dpst') }, { name: this.$t('charts.revenue'), icon: 'circle' }],
			};
			if (this.timeSwitch == 1) {
				this.revenueBar.title.text = this.$t('charts.overViewDpst');
			} else if (this.timeSwitch == 2) {
				this.revenueBar.title.text = this.$t('charts.overViewDpstM');
			}
		},
		// Avg. Revenue Per User / Avg. Revenue Per Pay User
		async avgRevenueChart(res) {
			const xAxisData = [];
			const arpuData = [];
			const arppuData = [];
			const legendData = [this.$t('charts.ARPUPD'), this.$t('charts.ARPPUPD')];
			if (res) {
				// if (this.timeSwitch == 1) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					arpuData.push(getNaN(res[i].dpst_amt / res[i].gm_users).toFixed(0));
					arppuData.push(getNaN(res[i].dpst_amt / res[i].dpst_users).toFixed(0));
				}
			}
			this.avgRevenueLine = await basicLineChart();
			this.avgRevenueLine.title.text = this.$t('charts.avgRevenueD');
			this.avgRevenueLine.xAxis.axisLabel.formatter = (value) => {
				return this.$moment(value).format(this.dateType);
			};
			this.avgRevenueLine.xAxis.data = xAxisData;
			this.avgRevenueLine.grid = { bottom: '24%', left: '13%' };
			this.avgRevenueLine.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				data: [
					{ name: legendData[1], icon: 'circle' },
					{ name: legendData[0], icon: 'circle' },
				],
			};
			// this.avgRevenueLine.tooltip.backgroundColor = 'rgba(50,50,50,0.7)';
			// this.avgRevenueLine.tooltip.textStyle.color = 'white';
			this.avgRevenueLine.tooltip.formatter = (params) => {
				let dateName;
				if (this.timeSwitch == 1) {
					dateName = this.$t('charts.date');
				} else if (this.timeSwitch == 2) {
					dateName = this.$t('charts.month');
				}
				let str = `${dateName}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
				for (let i of params) {
					str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
				}
				return str;
			};
			this.avgRevenueLine.series = [
				{
					type: 'line',
					name: this.$t('charts.ARPUPD'),
					data: arpuData,
					color: COLORS.light_purple_red,
				},
				{
					type: 'line',
					name: this.$t('charts.ARPPUPD'),
					data: arppuData,
					color: COLORS.emerald[6],
				},
			];
		},
		// user Chart
		async regChart(res) {
			const yAxisData = [],
				regUsers = [],
				loginUsers = [],
				gamingUsers = [],
				dpstUsers = [],
				secDpstUsers = [];
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					regUsers.push(res[i].avg_reg_users);
					loginUsers.push(res[i].avg_login_users);
					gamingUsers.push(res[i].avg_gm_users);
					dpstUsers.push(res[i].avg_dpst_01_users);
					secDpstUsers.push(res[i].avg_dpst_02_users);
					yAxisData.push(res[i].dim_ptt);
				}
				this.regFunnelChart(legendData);
			}
			this.regLine = await line();
			if (this.timeSwitch == 1) {
				this.regLine.title.text = this.$t('charts.dailyNewUsers');
			} else if (this.timeSwitch == 2) {
				this.regLine.title.text = this.$t('charts.monthlyNewUsers');
			}
			this.regLine.grid = { top: '22%', bottom: '24%', left: '13%' };
			this.regLine.legend = { top: 'bottom', icon: 'circle', textStyle: { color: '#FFF' }, data: Array.from(new Set(legendData)) };
			// this.regLine.tooltip.backgroundColor = 'rgba(50,50,50,0.7)';
			// this.regLine.tooltip.textStyle.color = 'white';
			this.regLine.tooltip.formatter = (params) => {
				let str = `${this.$moment(params[0].name).format(this.dateType)}<br/>`;
				for (let i of params) {
					str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
				}
				return str;
			};
			this.regLine.xAxis.axisLabel.formatter = (value) => {
				return this.$moment(value).format(this.dateType);
			};
			this.regLine.xAxis.data = Array.from(new Set(yAxisData));
			this.regLine.yAxis.axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.regLine.series = [
				{
					name: this.$t('charts.regUsers'),
					type: 'line',
					color: COLORS.dark_water_blue_green,
					data: regUsers,
				},
				{
					name: this.$t('charts.loginUsers'),
					type: 'line',
					color: COLORS.water_blue_green,
					data: loginUsers,
				},
				{
					name: this.$t('charts.gamingUsers'),
					type: 'line',
					color: COLORS.light_green,
					data: gamingUsers,
				},
				{
					name: this.$t('charts.dpstUsers'),
					type: 'line',
					color: COLORS.mustard,
					data: dpstUsers,
				},
				{
					name: this.$t('charts.secondDpst'),
					type: 'line',
					color: COLORS.wisteria[4],
					data: secDpstUsers,
				},
			];
		},
		async regPercentChart(res) {
			const regUsersData = {},
				loginUsersData = {},
				gamingUsersData = {},
				dpstUsersData = {},
				secDpstUsersData = {};
			const xAxisData = [],
				regUsers = [],
				loginUsers = [],
				gamingUsers = [],
				dpstUsers = [],
				secDpstUsers = [];
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					regUsers.push(res[i].avg_reg_users);
					loginUsers.push(res[i].avg_login_users);
					gamingUsers.push(res[i].avg_gm_users);
					dpstUsers.push(res[i].avg_dpst_01_users);
					secDpstUsers.push(res[i].avg_dpst_02_users);
					xAxisData.push(res[i].dim_ptt);
					regUsersData[res[i].dim_ptt] = res[i].avg_reg_users;
					loginUsersData[res[i].dim_ptt] = res[i].avg_login_users;
					gamingUsersData[res[i].dim_ptt] = res[i].avg_gm_users;
					dpstUsersData[res[i].dim_ptt] = res[i].avg_dpst_01_users;
					secDpstUsersData[res[i].dim_ptt] = res[i].avg_dpst_02_users;
				}
				this.regFunnelChart(legendData);
			}
			let allPercent = await this.getPercent(regUsers, loginUsers, gamingUsers, dpstUsers, secDpstUsers);
			this.regPercent = await barPercent();
			if (this.timeSwitch == 1) {
				this.regPercent.title.text = this.$t('charts.dailyNewUsers');
			} else if (this.timeSwitch == 2) {
				this.regPercent.title.text = this.$t('charts.monthlyNewUsers');
			}

			this.regPercent.grid = { top: '22%', bottom: '24%', left: '17%' };
			this.regPercent.legend = { top: 'bottom', height: 50, textStyle: { color: '#FFF' }, data: Array.from(new Set(legendData)) };
			this.regPercent.legend.selectedMode = false;
			// this.regPercent.tooltip.backgroundColor = 'rgba(50,50,50,0.7)';
			// this.regPercent.tooltip.textStyle.color = 'white';
			this.regPercent.toolbox.show = false;
			this.regPercent.tooltip.formatter = (params) => {
				let dateName;
				if (this.timeSwitch == 1) {
					dateName = this.$t('charts.date');
				} else if (this.timeSwitch == 2) {
					dateName = this.$t('charts.month');
				}
				let str = `${dateName}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
				if (params[4]) {
					str += `${params[4].marker}${params[4].seriesName}: ${roundOffFilter(regUsersData[params[0].name])}
					(${(
						Number(params[4].value) +
						Number(params[3].value) +
						Number(params[2].value) +
						Number(params[1].value) +
						Number(params[0].value)
					).toFixed(1)}%)<br/>`;
				}
				if (params[3]) {
					str += `${params[3].marker}${params[3].seriesName}: ${roundOffFilter(loginUsersData[params[0].name])}
					(${(Number(params[3].value) + Number(params[2].value) + Number(params[1].value) + Number(params[0].value)).toFixed(1)}%)<br/>`;
				}
				if (params[2]) {
					str += `${params[2].marker}${params[2].seriesName}: ${roundOffFilter(gamingUsersData[params[0].name])}
					(${(Number(params[2].value) + Number(params[1].value) + Number(params[0].value)).toFixed(1)}%)<br/>`;
				}
				if (params[1]) {
					str += `${params[1].marker}${params[1].seriesName}: ${roundOffFilter(dpstUsersData[params[0].name])}
					(${(Number(params[1].value) + Number(params[0].value)).toFixed(1)}%)<br/>`;
				}
				if (params[0]) {
					str += ` ${params[0].marker}${params[0].seriesName}: ${roundOffFilter(secDpstUsersData[params[0].name])}
					(${Number(params[0].value).toFixed(1)}%)<br/>`;
				}
				return str;
			};
			this.regPercent.xAxis.axisLabel.formatter = (value) => {
				return this.$moment(value).format(this.dateType);
			};
			this.regPercent.xAxis.data = Array.from(new Set(xAxisData));
			this.regPercent.series = [
				{
					name: this.$t('charts.secondDpst'),
					type: 'bar',
					stack: 'all',
					color: '#A569BD',
					data: allPercent.secDpstUsersArr,
				},
				{
					name: this.$t('charts.dpstUsers'),
					type: 'bar',
					stack: 'all',
					color: '#FFDB5C',
					data: allPercent.dpstUsersForBar,
				},
				{
					name: this.$t('charts.gamingUsers'),
					type: 'bar',
					stack: 'all',
					color: '#9FE6B8',
					data: allPercent.gamingUsersForBar,
				},
				{
					name: this.$t('charts.loginUsers'),
					type: 'bar',
					stack: 'all',
					color: '#67E0E3',
					data: allPercent.loginUsersForBar,
				},
				{
					name: this.$t('charts.regUsers'),
					type: 'bar',
					stack: 'all',
					color: '#32C5E9',
					data: allPercent.regUsersForBar,
				},
			];
		},
		async regFunnelChart(legendData) {
			this.funnelTmpData = {
				title: this.$t('charts.newUsersConversion'),
				subtext: this.$t('charts.avdDailyUsers'),
				legend: legendData,
				name: this.$t('charts.newUsersConversion'),
				data: {
					[this.$t('charts.regUsers')]: this.cvtAll.avg_reg_users,
					[this.$t('charts.loginUsers')]: this.cvtAll.avg_login_users,
					[this.$t('charts.gamingUsers')]: this.cvtAll.avg_gm_users,
					[this.$t('charts.dpstUsers')]: this.cvtAll.avg_dpst_01_users,
					[this.$t('charts.secondDpst')]: this.cvtAll.avg_dpst_02_users,
				},
				subTitle: [
					this.$t('charts.avgRegUsers'),
					this.$t('charts.avgLoginUsers'),
					this.$t('charts.avgGamingUsers'),
					this.$t('charts.avgDpstUsers'),
					this.$t('charts.avgSecondDpst')
				],
				subData: [
					this.cvtAll.ttl_reg_users,
					this.cvtAll.ttl_login_users,
					this.cvtAll.ttl_gm_users,
					this.cvtAll.ttl_dpst_01_users,
					this.cvtAll.ttl_dpst_02_users,
				]
			};
			this.regFunnel = await userFunnelChart(this.funnelTmpData);
		},
		// Site(Domain) Chart
		async domainChart(res, res2) {
			res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
			res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
			const tooltipObj = {};
			const data = [];
			const yAxisData = [];
			const percent = [];
			const rank = [];
			if (res && res2) {
				if (this.data == this.$t('charts.turnover')) {
					for (let i = 0; i < res.length; i += 1) {
						yAxisData.push(res[i].dim_site_id);
						data.push(res[i].apv_bet);
					}
				} else if (this.data == this.$t('charts.netWin')) {
					for (let i = 0; i < res2.length; i += 1) {
						yAxisData.push(res2[i].dim_site_id);
						data.push(res2[i].house_net_win);
					}
				}
			}
			const yAxisDataId = yAxisData.map((x) => x);
			const xx = data.reduce((total, val) => {
				return total + val;
			}, 0);
			for (let i = 0; i < data.length; i += 1) {
				percent.push(roundDecimal((data[i] / xx) * 100) || [0]);
			}
			for (let k = data.length; k > 0; k--) {
				rank.push(k);
			}
			for (let j = 0; j < data.length; j += 1) {
				yAxisData[j] = `${yAxisData[j]}: ${nFormatter(data[j], 1)} (${percent[j]}%)`;
				tooltipObj[yAxisDataId[j]] = `Rank : ${rank[j]}`;
			}
			this.domainBar = await bar();
			this.domainBar.color = COLORS.darker_blue;
			this.domainBar.title.text = this.$t('charts.SummaryDomain');
			this.domainBar.grid = { top: '23%', left: '5%' };
			this.domainBar.tooltip.backgroundColor = '#FFF';
			this.domainBar.tooltip.formatter = (params) => `
			<span style='color:#333';>${this.$t('charts.domain')}: ${params[0].name.toString().split(':', 1)}</span> <span style='color:#6C6C6C';>${
				tooltipObj[params[0].name.toString().split(':', 1)]
			}</span><br/>`;
			this.domainBar.xAxis.type = 'value';
			this.domainBar.xAxis.axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.domainBar.yAxis[0].data = yAxisData;
			this.domainBar.yAxis[0].axisLabel.formatter = (value) => {
				var arr = value.split(' ');
				return `{site|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
			};
			this.domainBar.yAxis[0].axisLabel.rich = {
				site: {
					color: '#FFF',
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #FFF',
				},
				amt: {
					color: COLORS.dark_mustard,
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #FFDC35',
				},
				percent: {
					color: '#FFF',
				},
			};
			this.domainBar.yAxis[0].type = 'category';
			this.domainBar.yAxis[0].axisLabel.inside = true;
			this.domainBar.yAxis[0].axisLine.onZero = false;
			this.domainBar.yAxis[0].zlevel = 1;
			if (yAxisData.length > 10) {
				this.domainBar.dataZoom = [
					{
						show: true,
						yAxisIndex: 0,
						filterMode: 'empty',
						textStyle: { color: 'none' },
						start: 80,
						end: 100,
						type: 'slider',
						showDetail: false,
						showDataShadow: false,
					},
				];
			}
			this.domainBar.series.data = data;
		},
		// Platform(Brand) Chart
		async brandChart(res, res2) {
			res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
			res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
			const tooltipObj = {};
			const data = [];
			const yAxisData = [];
			const percent = [];
			const rank = [];
			if (res && res2) {
				if (this.data == this.$t('charts.turnover')) {
					for (let i = 0; i < res.length; i += 1) {
						yAxisData.push(res[i].dim_brand_code);
						data.push(roundDecimal(res[i].apv_bet));
					}
				} else if (this.data == this.$t('charts.netWin')) {
					for (let i = 0; i < res2.length; i += 1) {
						yAxisData.push(res2[i].dim_brand_code);
						data.push(roundDecimal(res2[i].house_net_win));
					}
				}
			}
			const yAxisDataId = yAxisData.map((x) => x);
			const tmp = data.reduce((total, val) => {
				return total + val;
			}, 0);
			for (let i = 0; i < data.length; i += 1) {
				percent.push(roundDecimal((data[i] / tmp) * 100) || [0]);
			}
			for (let k = data.length; k > 0; k--) {
				rank.push(k);
			}
			for (let j = 0; j < data.length; j += 1) {
				yAxisData[j] = `${yAxisData[j]}: ${nFormatter(data[j], 1)} (${percent[j]}%)`;
				tooltipObj[yAxisDataId[j]] = `Rank : ${rank[j]}`;
			}
			this.brandBar = await bar();
			this.brandBar.color = '#598e6b';
			this.brandBar.title.text = this.$t('charts.SummaryPlatform');
			this.brandBar.grid = { top: '23%', left: '5%' };
			this.brandBar.xAxis.type = 'value';
			this.brandBar.xAxis.axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.brandBar.yAxis[0].data = yAxisData;
			this.brandBar.yAxis[0].type = 'category';
			this.brandBar.yAxis[0].axisLine.onZero = false;
			this.brandBar.yAxis[0].axisLabel.inside = true;
			this.brandBar.yAxis[0].axisLabel.formatter = (value) => {
				var arr = value.split(' ');
				return `{site|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
			};
			this.brandBar.yAxis[0].axisLabel.rich = {
				site: {
					color: '#FFF',
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #FFF',
				},
				amt: {
					color: '#ffc964',
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #c8e6c9',
				},
				percent: {
					color: '#FFF',
				},
			};
			this.brandBar.yAxis[0].zlevel = 1;
			if (yAxisData.length > 10) {
				this.brandBar.dataZoom = [
					{
						show: true,
						yAxisIndex: 0,
						filterMode: 'empty',
						textStyle: { color: 'none' },
						start: 80,
						end: 100,
						type: 'slider',
						showDetail: false,
						showDataShadow: false,
					},
				];
			}
			this.brandBar.tooltip.backgroundColor = '#FFF';
			this.brandBar.tooltip.formatter = (params) => `
			<span style='color:#333';>${this.$t('charts.platform')}: ${params[0].name.toString().split(':', 1)}</span> <span style='color:#6C6C6C';>${
				tooltipObj[params[0].name.toString().split(':', 1)]
			}</span><br/>`;
			this.brandBar.series.data = data;
		},
		// Game Kind Chart
		async gameTypeChart(res, res2) {
			res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
			res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
			const tooltipObj = {};
			const data = [];
			const yAxisData = [];
			const percent = [];
			const rank = [];
			if (res && res2) {
				if (this.data == this.$t('charts.turnover')) {
					for (let i = 0; i < res.length; i += 1) {
						yAxisData.push(res[i].game_kind_name);
						data.push(roundDecimal(res[i].apv_bet));
					}
				} else if (this.data == this.$t('charts.netWin')) {
					for (let i = 0; i < res2.length; i += 1) {
						yAxisData.push(res2[i].game_kind_name);
						data.push(roundDecimal(res2[i].house_net_win));
					}
				}
			}
			const yAxisDataId = yAxisData.map((x) => x);
			const tmp = data.reduce((total, val) => {
				return total + val;
			}, 0);
			for (let i = 0; i < data.length; i += 1) {
				percent.push(roundDecimal((data[i] / tmp) * 100) || [0]);
			}
			for (let k = data.length; k > 0; k--) {
				rank.push(k);
			}
			for (let j = 0; j < data.length; j += 1) {
				yAxisData[j] = `${yAxisData[j]}: \n${nFormatter(data[j], 1)} (${percent[j]}%)`;
				tooltipObj[yAxisDataId[j]] = `Rank : ${rank[j]}`;
			}
			this.gameTypeBar = await bar();
			this.gameTypeBar.color = COLORS.indigo;
			this.gameTypeBar.title.text = this.$t('charts.SummaryGameKind');
			this.gameTypeBar.grid = { top: '23%', left: '5%' };
			this.gameTypeBar.xAxis.type = 'value';
			this.gameTypeBar.xAxis.axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.gameTypeBar.yAxis[0].type = 'category';
			this.gameTypeBar.yAxis[0].axisLine.onZero = false;
			this.gameTypeBar.yAxis[0].axisLabel.inside = true;
			this.gameTypeBar.yAxis[0].axisLabel.formatter = (value) => {
				var arr = value.split(' ');
				return `{site|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
			};
			this.gameTypeBar.yAxis[0].axisLabel.rich = {
				site: {
					color: '#FFF',
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #FFF',
				},
				amt: {
					color: '#ffc964',
					fontWeight: 900,
					textShadow: '0.5px 0.5px 0.5px #BEBEBE',
				},
				percent: {
					color: '#FFF',
					width: '100%',
				},
			};
			this.gameTypeBar.yAxis[0].zlevel = 1;
			this.gameTypeBar.yAxis[0].data = yAxisData;
			if (yAxisData.length > 10) {
				this.gameTypeBar.dataZoom = [
					{
						show: true,
						yAxisIndex: 0,
						filterMode: 'empty',
						textStyle: { color: 'none' },
						start: 80,
						end: 100,
						type: 'slider',
						showDetail: false,
						showDataShadow: false,
					},
				];
			}
			this.gameTypeBar.tooltip.backgroundColor = '#FFF';
			this.gameTypeBar.tooltip.formatter = (params) => `
			<span style='color:#333';>${this.$t('charts.gameKind')}: ${params[0].name
				.toString()
				.split(':', 1)}</span> <br/><span style='color:#6C6C6C';>${
				tooltipObj[params[0].name.toString().split(':', 1)]
			}</span><br/>`;
			this.gameTypeBar.series.data = data;
		},
		// Device Chart
		async deviceChart(res) {
			const sum = [], data = [];
			const legendData = Object.values(deviceType);
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					sum.push(res[i].avg_login_count);
				}
				for(let i of legendData) {
					data.push({
						value: null,
						name: i,
					});
				}
				for(let i of data) {
					for (let j of res) {
						if (i.name === j.dim_device_id) {
							i.value = j.avg_login_count;
						}
					}
				}
			}
			this.allLoginCount = sum.reduce((total, val) => {
				return total + val;
			}, 0);
			this.devicePie = norPie();
			this.devicePie.color = this.countColor;
			this.devicePie.title.text = this.$t('charts.dviceCount');
			this.devicePie.title.textStyle.fontSize = 20;
			this.devicePie.legend.data = legendData;
			// this.devicePie.grid.top = '15%';
			this.devicePie.graphic.style.text = this.$t('charts.totalDeviceCount') + '\n' + roundOffFilter(this.allLoginCount);
			this.devicePie.tooltip.formatter = (params) => `
				${params.marker}${params.name}: ${roundOffFilter(params.value)} (${rdPercent(params.value / this.allLoginCount)})`;
			this.devicePie.series[0].data = data;
		},
		async deviceLineChart(res) {
			let xAxisData = [];
			let webArr = [],  appArr = [], pwa2Arr = [], lineArr = [], pwa4Arr = [], unArr = [];
			const legendData = Object.values(deviceType);
			const tmp = {
				'Web': [],
				'App': [],
				'PWA-2': [],
				'Line': [],
				'PWA-4': [],
				'N/A': [],
			};
			if(res) {
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					for (let j of legendData) {
						if (i.dim_device_id === j) {
							tmp[i.dim_device_id].push(i);
						}
					}
				}
				xAxisData = Array.from(new Set(xAxisData));
				this.countPercent = await this.getCPercent(tmp['Web'], tmp['App'], tmp['PWA-2'], tmp['Line'], tmp['PWA-4'], tmp['N/A']);
				for (let i in tmp) {
					if(tmp[i].length > 0) {
						for (let j of tmp[i]) {
							if(j.dim_device_id === 'Web') { webArr.push(j.login_count); }
							if(j.dim_device_id === 'App') { appArr.push(j.login_count); }
							if(j.dim_device_id === 'PWA-2') { pwa2Arr.push(j.login_count); }
							if(j.dim_device_id === 'Line') { lineArr.push(j.login_count); }
							if(j.dim_device_id === 'PWA-4') { pwa4Arr.push(j.login_count); }
							if(j.dim_device_id === 'N/A') { unArr.push(j.login_count); }
						}
					}
				}
				const countData = [
					{
						type: 'line',
						name: 'Web',
						data: webArr,
						color: this.countColor[0],
					},
					{
						type: 'line',
						name: 'App',
						data: appArr,
						color: this.countColor[1],
					},{
						type: 'line',
						name: 'PWA-2',
						data: pwa2Arr,
						color: this.countColor[2],
					},
					{
						type: 'line',
						name: 'Line',
						data: lineArr,
						color: this.countColor[3],
					},
					{
						type: 'line',
						name: 'PWA-4',
						data: pwa4Arr,
						color: this.countColor[4],
					},
					{
						type: 'line',
						name: 'N/A',
						data: unArr,
						color: this.countColor[5],
					}
				];
				this.countLine = norLine({
					title: '',
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: countData,
				});
				this.countLine.xAxis.axisLabel.formatter = (value) => {
					return this.$moment(value).format(this.dateType);
				};
				this.countLine.tooltip.formatter = (params) => {
					let dateName;
					if (this.timeSwitch === 1) {
						dateName = this.$t('charts.date');
					}
					if (this.timeSwitch === 2) {
						dateName = this.$t('charts.month');
					}
					let str = `${dateName}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		async deviceBarChart(res) {
			const legendData = Object.values(deviceType);
			let xAxisData = [], tmp = [];
			if (res) {
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
				}
				for (let j in legendData) {
					tmp.push({
						name: legendData[j],
						type: 'bar',
						stack: 'all',
						data: this.countPercent[j],
					});
				}
				xAxisData = Array.from(new Set(xAxisData));
				this.countBar = norBar({
					color: this.countColor,
					title: '',
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: tmp,
				});
				this.countBar.xAxis.axisLabel.formatter = (value) => {
					return this.$moment(value).format(this.dateType);
				};
				this.countBar.tooltip.formatter = (params) => {
					let dateName;
					if (this.timeSwitch === 1) {
						dateName = this.$t('charts.date');
					}
					if (this.timeSwitch === 2) {
						dateName = this.$t('charts.month');
					}
					let te = `${dateName}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for(let i of params) {
						te += `${i.marker}${i.seriesName}: ${roundDecimal(i.value)}%<br/>`;
					}
					return te;
				}
			}
		},
		getCPercent(web, app, pwa2, line, pwa4, unk) {
			let webArr = [], appArr = [], pwa2rr = [], lineArr = [], pwa4Arr = [], unkArr = [];
			for(let i of this.dailyCount) {
				for(let j of web) {
					if(i.dim_ptt === j.dim_ptt) {
						webArr.push((j.login_count / i.login_count * 100));
					}
				}
				for(let j of app) {
					if(i.dim_ptt === j.dim_ptt) {
						appArr.push((j.login_count / i.login_count * 100));
					}
				}
				for(let j of pwa2) {
					if(i.dim_ptt === j.dim_ptt) {
						pwa2rr.push((j.login_count / i.login_count * 100));
					}
				}
				for(let j of line) {
					if(i.dim_ptt === j.dim_ptt) {
						lineArr.push((j.login_count / i.login_count * 100));
					}
				}
				for(let j of pwa4) {
					if(i.dim_ptt === j.dim_ptt) {
						pwa4Arr.push((j.login_count / i.login_count * 100));
					}
				}
				for(let j of unk) {
					if(i.dim_ptt === j.dim_ptt) {
						unkArr.push((j.login_count / i.login_count * 100));
					}
				}
			}
			return [webArr, appArr, pwa2rr, lineArr, pwa4Arr, unkArr];
		},
		allClean() {
			this.allLoginCount = 0;
			this.turnOverBar = {};
			this.netWinBar = {};
			this.acuLine = {};
			this.regLine = {};
			this.regPercent = {};
			this.regFunnel = {};
			this.domainBar = {};
			this.brandBar = {};
			this.gameTypeBar = {};
			this.devicePie = {};
			this.revenueBar = {};
			this.avgRevenueLine = {};
			this.mauBar = {};
			this.countLine = {};
			this.countBar = {};
			this.basic.items = [];
			this.netWinByDomain = [];
			this.netWinByBrand = [];
			this.domainROE = {};
			this.brandROE = {};
			this.gameKindROE = {};
			this.getExportResult = {};
		},
		toInt(data) {
			return _.toInteger(data.replace(/,/g, ''));
		},
		centNumToInteger(number) {
			return commaFormatter(number);
		},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.domainMap = val2;
			this.currency = val3;
		},
		updateDate(val) {
			this.dates = val;
		},
		updateMonth(val) {
			this.dates = val;
		},
		async updateData(val) {
			this.data = val;
			if (this.timeSwitch === 1) {
				if (Object.keys(this.getDailyResult).length === 0) {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
			} else if (this.timeSwitch === 2) {
				if (Object.keys(this.getMonthlyResult).length === 0) {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
			}
			this.gaming.sort((a, b) => (a.dim_site_id > b.dim_site_id ? 1 : -1));
			this.domainChart(await trans(this.gaming, 'dim_site_id', 'apv_bet'), await trans(this.gaming, 'dim_site_id', 'house_net_win'));
			this.gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
			this.brandChart(await trans(this.gaming, 'dim_brand_code', 'apv_bet'), await trans(this.gaming, 'dim_brand_code', 'house_net_win'));
			this.gaming.sort((a, b) => (a.game_kind_name > b.game_kind_name ? 1 : -1));
			this.gameTypeChart(
				await trans(this.gaming, 'game_kind_name', 'apv_bet'),
				await trans(this.gaming, 'game_kind_name', 'house_net_win')
			);
		},
		async updateNetWinData(val) {
			this.netWinData = val;
			if (this.timeSwitch === 1) {
				if (this.getNetWinDailyResult.length !== 0) {
					this.netWinChart(this.termly);
				} else {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
			}
			if (this.timeSwitch === 2) {
				if (this.getNetWinDailyResult.length !== 0) {
					this.netWinChart(this.termly);
				} else {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
			}
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				result.ctx_cvt_all = [result.ctx_cvt_all];
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		getPercent(regUsers, loginUsers, gamingUsers, dpstUsers, secDpstUsers) {
			let regUsersArr = [],
				loginUsersArr = [],
				gamingUsersArr = [],
				dpstUsersArr = [],
				secDpstUsersArr = [],
				dpstUsersForBar = [],
				gamingUsersForBar = [],
				loginUsersForBar = [],
				regUsersForBar = [];
			for (var i = 0; i < regUsers.length; i++) {
				regUsersArr.push(roundDecimal((regUsers[i] / regUsers[i]) * 100));
				loginUsersArr.push(roundDecimal((loginUsers[i] / regUsers[i]) * 100));
				gamingUsersArr.push(roundDecimal((gamingUsers[i] / regUsers[i]) * 100));
				dpstUsersArr.push(roundDecimal((dpstUsers[i] / regUsers[i]) * 100));
				secDpstUsersArr.push(roundDecimal((secDpstUsers[i] / regUsers[i]) * 100));
				dpstUsersForBar.push(roundDecimal(dpstUsersArr[i] - secDpstUsersArr[i]));
				gamingUsersForBar.push(roundDecimal(gamingUsersArr[i] - dpstUsersArr[i]));
				loginUsersForBar.push(roundDecimal(loginUsersArr[i] - gamingUsersArr[i]));
				regUsersForBar.push(roundDecimal(regUsersArr[i] - loginUsersArr[i]));
			}
			let allDataPercent = {
				regUsersArr,
				loginUsersArr,
				gamingUsersArr,
				dpstUsersArr,
				secDpstUsersArr,
				regUsersForBar,
				loginUsersForBar,
				gamingUsersForBar,
				dpstUsersForBar,
			};
			return allDataPercent;
		},
		legendselectchanged(params) {
			const selected = params.selected;
			if (selected) {
				const key = Object.keys(selected);
				const val = Object.values(selected);
				const noSelect = []; // not selected
				for (let i in val) {
					if (val[i]) {
						noSelect.push(key[i]);
					}
				}
				this.regFunnel.series[0].label.formatter = (params) => {
					let tmp = rdPercent(this.funnelTmpData.data[params.name] / this.funnelTmpData.data[noSelect[0]]);
					return `${params.name}\n${toThousandslsFilter(this.funnelTmpData.data[params.name])}\n${tmp}`;
				};
				this.regFunnel.tooltip.formatter = (params) => {
					let tmp = rdPercent(this.funnelTmpData.data[params.name] / this.funnelTmpData.data[noSelect[0]]);
					return `${params.marker}${params.name}: ${toThousandslsFilter(this.funnelTmpData.data[params.name])} (${tmp})`;
				};
			}
		},
	},
	watch: {
		tab: function () {
			this.allClean();
			this.helperShow = false;
			this.percentSwitch = false;
			if (this.tab == 0) {
				this.timeSwitch = 1;
				this.setOperatorDefaultRouterTab('daily');
			} else if (this.tab == 1) {
				this.timeSwitch = 2;
				this.setOperatorDefaultRouterTab('monthly');
			}
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let adscrtop = $('.div-w').offset().top;

					if (scrollVal + 44 > adscrtop) {
						$('.fixBar').addClass('dateFixBar');
					} else {
						$('.fixBar').removeClass('dateFixBar');
					}
				});
			});
		},
		percentSwitch: function () {
			if(!this.percentSwitch) {
				this.$refs.regPercent.clear();
				this.regChart(this.cvtOrg);
			} else {
				this.$refs.regLine.clear();
				this.regPercentChart(this.cvtOrg);
			}
		},
		getExportResult: function () {
			if (Object.keys(this.getExportResult).length !== 0) {
				this.helperShow = false;
			}
		},
	},
	computed: {
		...mapGetters(['getExchangeRate', 'getDialogShow', 'getStatusDialog', 'getOperatorDefaultRouter', 'getOpenAutoSearch']),
	},
	created() {
		this.allClean();
	},
};
</script>
