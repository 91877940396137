import light from '@/theme/light';

export function mixBar_Line() {
	const option = {
		color: light.color,
		title: {
			text: '',
			subtext: '',
			textStyle: {
				color: '#FFF'
			}
		},
		grid: {},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar'
					},
					type: ['line', 'bar'] // 'stack'
				}
			}
		},
		tooltip: {
			axisPointer: {
				type: 'shadow'
			},
			textStyle: {
				color: 'black',
			},
			confine: true,
			formatter: '',
			trigger: 'axis',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8'
		},
		xAxis: {
			name: '',
			type: 'category',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white'
				}
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF'
			},
			data: []
		},
		yAxis: [
			{
				name: '',
				type: 'value',
				axisTick: { show: false },
				splitArea: { show: false },
				axisLine: { lineStyle: { color: 'white' } },
				splitLine: { lineStyle: { color: '#37474F' } },
				axisLabel: {
					show: true,
					color: '#FFF'
				},
				// eslint-disable-next-line no-dupe-keys
				splitLine: { show: false },
			},
			//雙Ｙ軸 右側Ｙ軸百分比
			{
				name: '',
				type: 'value',
				axisTick: { show: false },
				axisLine: { lineStyle: { color: 'white'} },
				axisLabel: {
					show: true,
					formatter: '{value}%',
					color: '#FFF'
				},
				splitLine: { lineStyle: { color: '#37474F' } },
			}
		],
		series: []
	};
	return option;
}